<template>
  <v-container
    id="oauthApps"
    fluid
    tag="section"
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
    </div>

    <base-material-card
      color="indigo"
      icon="mdi-grid"
      inline
      class="px-5 py-3"
      hover-reveal
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light mt-2">
          OAuth App List
        </div>
      </template>

      <v-row class="align-center">
        <v-spacer />

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          label="Search"
          hide-details
          style="max-width: 250px; margin-bottom: 5px; margin-right: 12px"
        />

        <v-btn
          color="primary"
          class="mx-3"
          @click="openRegDialog"
        >
          <v-icon left>
            mdi-view-grid-plus-outline
          </v-icon>
          New App
        </v-btn>
      </v-row>

      <v-divider class="mt-3" />

      <v-data-table
        :headers="headers"
        :items="apps"
        :search.sync="search"
        multi-sort
        sort-desc
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-btn
                class="ma-2"
                color="blue"
                outlined
                fab
                x-small
                v-bind="attrs"
                v-on="on"
                @click="openEditDialog(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-btn
                class="ma-2"
                outlined
                fab
                x-small
                v-bind="attrs"
                v-on="on"
                @click="deleteApp(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <!-- Edit Form -->
      <v-dialog
        v-model="editDialog"
        max-width="800px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Edit OAuth App</span>
          </v-card-title>

          <v-card-text>
            <v-form
              ref="formEdit"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="5"
                >
                  <v-text-field
                    v-model="editedApp.clientID"
                    label="Client ID"
                    readonly
                    filled
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="editedApp.appSecret"
                    label="Client Secret"
                    readonly
                    filled
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="get_secret(editedApp)"
                  >
                    <v-icon>mdi-reload</v-icon>
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="editedApp.appName"
                    label="Application Name*"
                    hint="Something users will recognize and trust."
                    :rules="[rules.appName.notEmpty, rules.appName.characters]"
                    persistent-hint
                    required
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="editedApp.appDesc"
                    label="App Description"
                    hint="This is displayed to all users of your application."
                    :rules="[rules.appDesc.notEmpty, rules.appDesc.characters]"
                    persistent-hint
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="editedApp.homeURL"
                    label="Homepage URL"
                    hint="The full URL to your application homepage."
                    :rules="[rules.homeURL]"
                    persistent-hint
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="editedApp.callbackURL"
                    label="Authorization callback URL"
                    hint="Your application's callback URL."
                    :rules="[rules.callbackURL]"
                    persistent-hint
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              min-width="100"
              @click="editDialog = false"
            >
              Cancel
            </v-btn>

            <v-btn
              color="blue darken-1"
              min-width="100"
              @click="edit_save(editedApp)"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Register Form -->
      <v-dialog
        v-model="regisDialog"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Register OAuth App</span>
          </v-card-title>

          <v-card-text>
            <v-form
              ref="formAdd"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                >
                  <v-text-field
                    v-model="newApp.appName"
                    label="Application Name *"
                    hint="Something users will recognize and trust."
                    :rules="[rules.appName.notEmpty, rules.appName.characters]"
                    persistent-hint
                    required
                    :counter="50"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                >
                  <v-text-field
                    v-model="newApp.appDesc"
                    label="App Description *"
                    hint="This is displayed to all users of your application."
                    :rules="[rules.appDesc.notEmpty, rules.appDesc.characters]"
                    persistent-hint
                    :counter="255"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                >
                  <v-text-field
                    v-model="newApp.homeURL"
                    label="Homepage URL *"
                    hint="The full URL to your application homepage."
                    :rules="[rules.homeURL]"
                    persistent-hint
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                >
                  <v-text-field
                    v-model="newApp.callbackURL"
                    label="Authorization callback URL *"
                    hint="Your application's callback URL."
                    :rules="[rules.callbackURL]"
                    persistent-hint
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              min-width="100"
              @click="regisDialog = false"
            >
              Cancel
            </v-btn>

            <v-btn
              color="blue darken-1"
              min-width="100"
              @click="register(newApp)"
            >
              Register
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </base-material-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { getAccessToken } from '../../../utils/auth'

  export default {
    name: 'DashboardExtendedTables',

    data: () => ({
      loading: false,
      regisDialog: false,
      editDialog: false,
      valid: true,
      fom: undefined,
      apps: [],
      urls: {
        load: '/v1/api/apps',
        post: '/v1/api/apps/add',
        put: '/v1/api/apps/update',
        delete: '/v1/api/apps/delete',
        secret: '/v1/api/apps/secret',
      },
      headers: [
        { text: 'Application Name', value: 'appName' },
        { text: 'Application Description', value: 'appDesc' },
        { text: 'Client ID', value: 'clientID' },
        { text: 'Client Secret', value: 'appSecret' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedClientId: '',
      newApp: {
        appName: '',
        appDesc: '',
        clientID: '',
        appSecret: '',
        homeURL: '',
        callbackURL: '',
        valid: false,
      },
      editedApp: {
        appName: '',
        appDesc: '',
        clientID: '',
        appSecret: '',
        homeURL: '',
        callbackURL: '',
        valid: false,
      },
      defaultApp: {
        appName: '',
        appDesc: '',
        clientID: '',
        appSecret: '',
        homeURL: '',
        callbackURL: '',
        valid: false,
      },
      search: undefined,
      rules: {
        appName: {
          characters: value => {
            const pattern = /^((\w+( \w+)*){1,50})$/
            return pattern.test(value) || 'Application Name is not valid'
          },
          notEmpty: v => !!v || 'Required',
        },
        appDesc: {
          characters: value => {
            const pattern = /^((.){1,255})$/
            return pattern.test(value) || 'Application Description is not valid'
          },
          notEmpty: v => !!v || 'Required',
        },
        homeURL: value => {
          const pattern = /(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/
          return pattern.test(value) || 'Homepage URL is not valid'
        },
        callbackURL: value => {
          const pattern = /(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/
          return pattern.test(value) || 'Homepage URL is not valid'
        },
      },
    }),

    watch: {
      regisDialog: function (n, o) {
        this.$nextTick(() => {
          if (this.$refs.formAdd) {
            // this.$refs.formAdd.reset()
            // Object.assign(this.newApp, this.defaultApp)
            this.resetFormAdd()
          }
        })
        // if (!n) this.resetFormAdd()
      },

      editDialog: function (n, o) {
        if (!n) this.resetFormEdit()
      },
    },

    mounted () {
      this.loadItems()
    },

    methods: {
      async loadItems () {
        this.loading = true
        await axios.get(this.urls.load, { withCredentials: false })
          .then((response) => {
            this.apps = (response.data.data) ? response.data.data : []
            // console.log(response.data.data)
          }).catch(e => {
            this.handleErrors(e)
          }).finally(() => (this.loading = false)) // set loading to false when request finish
      },

      openRegDialog () {
        this.regisDialog = true
      },

      async register (item) {
        // console.log('reg valid', this.$refs.form.validate())
        if (!this.$refs.formAdd.validate()) return
        this.loading = true // the loading begin
        await axios.post(
          this.urls.post,
          item,
          {
            headers: { Authorization: `Bearer ${getAccessToken()}`, 'Content-Type': 'application/json' },
            withCredentials: false,
          },
        ).then((response) => {
          // console.log(response)
          this.show_message('success', 'Application successfully added!')
          this.apps.push(response.data.data)
          this.regisDialog = false
        }).catch(e => {
          this.handleErrors(e)
        }).finally(() => (this.loading = false)) // set loading to false when request finish
      },

      openEditDialog (item) {
        this.editedIndex = this.apps.indexOf(item)
        this.editedClientId = item.clientID
        Object.assign(this.editedApp, item)
        this.editDialog = true
      },

      async get_secret (item) {
        this.loading = true
        const url = `${this.urls.secret}/${item.clientID}`
        await axios.get(url).then((response) => {
          // console.log(response)
          this.editedApp.appSecret = response.data.appSecret
          Object.assign(this.apps[this.editedIndex], this.editedApp)
        }).catch(e => {
          this.handleErrors(e)
        }).finally(() => (this.loading = false)) // set loading to false when request finish
      },

      async edit_save (item) {
        // console.log('validate', this.$refs.form.validate())
        if (!this.$refs.formEdit.validate()) return
        const url = `${this.urls.put}/${item.clientID}`
        this.loading = true // the loading begin
        await axios.put(
          url,
          item,
        ).then((response) => {
          // console.log(response)
          Object.assign(this.apps[this.editedIndex], response.data.data)

          this.$swal.fire({
            titleText: `App ${this.apps[this.editedIndex].appName} has been updated`,
            icon: 'success',
            showCancelButton: false,
          })
          this.editDialog = false
        }).catch(e => {
          this.handleErrors(e)
        }).finally(() => (this.loading = false)) // set loading to false when request finish
      },

      async deleteApp (item) {
        this.$swal.fire({
          titleText: `Are you sure you want to delete ${item.appName}?`,
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#dd3333',
          confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
          if (result.value) {
            this.delete_agree(item)
            // this.delete_dialog = true
          }
        })
      },

      async delete_agree (item) {
        this.loading = true // the loading begin
        const index = this.apps.indexOf(item)
        await axios.delete(`${this.urls.delete}/${item.clientID}`).then(response => {
          // console.log('delete response', response)
          this.apps.splice(index, 1)
          this.$swal.fire({
            titleText: `App ${item.appName} has been deleted`,
            icon: 'success',
            showCancelButton: false,
          })
        }).catch(e => {
          this.handleErrors(e)
        }).finally(() => (this.loading = false)) // set loading to false when request finish
      },

      resetFormAdd () {
        /*        this.formHasErrors = false
        Object.keys(this.form).forEach(f => {
          this.$refs[f].reset()
          this.$refs[f].resetValidation()
        }) */
        // Object.assign(this.newApp, this.defaultApp)
        // Object.assign(this.editedApp, this.defaultApp)
        // console.log('newApp', this.newApp)
        this.$refs.formAdd.reset()
        // this.$refs.formAdd.resetValidation()
        // this.$refs.form.resetValidation()
        // this.$refs.appName.reset()
      },

      resetFormEdit () {
        this.$refs.formEdit.reset()
      },

      handleErrors (error) {
        if (error.response === undefined) {
          this.show_message('error', error.toString())
        } else if (!error.response.data.success) {
          if (error.response.data.errorMessage) {
            this.show_message('error', error.response.data.errorMessage)
          } else if (error.response.data.validations) {
            this.show_message('error', error.response.data.validations[0].message)
          } else {
            this.show_message('error', error.response.data.message)
          }
        } else {
          this.show_message('error', 'api service not available!')
        }
      },

      show_message (type, text) {
        // type = 'success', 'info', 'error' ... color string
        this.$swal.fire({
          icon: type,
          titleText: type,
          text: text,
          timer: 5000,
        })
      },
    },
  }
</script>

<style lang="sass">
#oauthApps
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>th, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>th, .v-data-table>.v-data-table__wrapper>table>thead>tr>th
    font-size: .85rem !important
</style>
